import api from "@/api/index";

export default {
  create(credentials) {
    return api().post("prompt/create", credentials);
  },

  selling(credentials) {
    return api().post("prompt/selling", credentials);
  },
  byId(_id, credentials) {
    return api().get("prompt/detail/" + _id, credentials);
  },
  featuredPrompts(credentials) {
    return api().get("prompt/featuredPrompts", credentials);
  },
  trendPrompts(credentials) {
    return api().get("prompt/trendPrompts", credentials);
  },
  newPrompts(credentials) {
    return api().get("prompt/newPrompts", credentials);
  },

  submit(credentials) {
    return api().post("prompt/submit", credentials);
  },
  buyer(credentials) {
    return api().post("prompt/buyer", credentials);
  },
  favorite(credentials) {
    return api().post("prompt/favorite", credentials);
  },
  filter(credentials) {
    return api().post("prompt/filter", credentials);
  },

  AdminListPrompt(credentials) {
    return api().post("admin/auditList", credentials);
  },
  approveList(credentials) {
    return api().post("admin/approve", credentials);
  },
  getFree(credentials) {
    return api().post("prompt/getFree", credentials);
  },
  freePrompt(credentials) {
    return api().get("prompt/freePrompt", credentials);
  },
  sexyPrompt(credentials) {
    return api().get("prompt/sexyPrompt", credentials);
  },
  deletePrompt(credentials) {
    return api().post("prompt/deletePrompt", credentials);
  },
  homeDetail(credentials) {
    return api().get("prompt/homeDetail", credentials);
  },
};
