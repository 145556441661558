<template>
  <div>
    <div class="z-0">
      <header>
        <ConnectWallet />
      </header>

      <div
        class="mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-2xl mt-10 p-2"
      >
        <div class="grid grid-cols-1 gap-2 lg:grid-cols-7 text-gray-300">
          <div class="sm:col-span-1 col-span-4 mt-1 sm:mt-10">
            <div class="lg:hidden md:block sm:block flex-grow text-right">
              <button
                v-on:click="changeMenu()"
                class="flex-none text-sm px-6 py-2 border rounded-xl text-gray-200 border-none bg-gradient-to-r from-green-500 to-blue-600 bg-opacity-25"
              >
                Search
                <i class="fas fa-search ml-3"></i>
              </button>
            </div>

            <div style="font-size: 14px" :class="showMenu">
              <div>
                <div>
                  <h3
                    class="text-sm font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-400 via-purple-500 to-blue-500"
                  >
                    Sort by
                  </h3>
                </div>
                <div class="mt-2 grid grid-cols-3 gap-0 lg:grid-cols-1">
                  <div class="" v-for="m in mode" :key="m">
                    <input
                      type="checkbox"
                      class="cursor-pointer"
                      v-model="m.value"
                      @click="changeMode(m.name)"
                    />
                    {{ m.name }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-span-6 sm:mt-1 mt-10">
            <h3
              class="text-xl font-bold xl:text-xl text-transparent bg-clip-text bg-gradient-to-r from-green-400 via-purple-500 to-blue-500 transition duration-300 ease-in-out transform hover:-translate-y-1 cursor-pointer"
            >
              Trending Prompts
            </h3>

            <hr class="h-px my-3 bg-gray-200 border-0 dark:bg-gray-700" />

            <div class="grid grid-cols-3 gap-3 lg:grid-cols-5 md:grid-cols-4">
              <div
                class="transition duration-300 ease-in-out transform hover:-translate-y-1 cursor-pointer rounded-sm rounded"
                style="margin-top: 10px"
                v-for="s in prompts"
                :key="s"
              >
                <div
                  class="p-0.5 bg-opacity-25 bg-gradient-to-r from-green-500 via-pink-600 to-green-300 rounded"
                >
                  <div class="text-sm border-none rounded bg-gray-900">
                    <a target="_blank" :href="'/promptAdmin/' + s._id">
                      <img
                        :src="s.img_1"
                        alt="Image 1"
                        class="ease-in-out opacity-100 hover:opacity-0"
                      />

                      <img
                        :src="s.img_2"
                        alt="Image 2"
                        class="absolute inset-0 object-cover transition duration-500 ease-in-out opacity-0 hover:opacity-100"
                      />
                      <div class="flex -mt-7 ml-2">
                        <div class="flex-none">
                          <div
                            class="flex border rounded bg-gray-500 bg-opacity-25"
                            v-if="s.audit == 'approve'"
                          >
                            <div class="text-xs p-1 text-green-300">
                              <i class="fa fa-check-square text-green-500"></i>
                              {{ s.prompt_type }}
                            </div>
                          </div>
                          <div
                            class="flex border rounded bg-yellow-500 bg-opacity-75"
                            v-else-if="s.audit == 'wait approve'"
                          >
                            <div class="text-xs p-1 text-green-100">
                              wait approve
                            </div>
                          </div>
                          <div
                            class="flex border rounded bg-red-500 bg-opacity-75"
                            v-else-if="s.audit == 'reject'"
                          >
                            <div class="text-xs p-1 text-green-100">Reject</div>
                          </div>
                        </div>

                        <div
                          class="flex-grow text-right text-gray-200 mr-2 hidden lg:block"
                        >
                          <h3 class="text-sm font-bold">
                            <i class="fas fa-eye"></i>

                            {{ s.view }}

                            <i class="fas fa-heart ml-2"></i>

                            {{ s.favorite }}
                          </h3>
                        </div>
                      </div>

                      <div class="flex"></div>

                      <div class="flex bg-black pt-1">
                        <div class="flex-none">
                          <h4 class="text-xs p-2 mt-1 text-gray-200">
                            ⛵ {{ s.title.slice(0, 12) }} ...
                          </h4>
                        </div>

                        <div
                          class="flex-grow p-2 text-right text-gray-200 hidden lg:block"
                        >
                          {{ s.price }}
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ConnectWallet from "@/components/ConnectWallet.vue";

import { mapGetters, mapActions } from "vuex";

import Prompt from "@/api/prompt";

import { ADMIN } from "../../../config";

export default {
  data() {
    return {
      products: [],
      products_2: [],
      prompts: [],
      showMenu: "hidden lg:block",
      mode: [
        { name: "approve", value: true },
        { name: "wait approve", value: false },
        { name: "reject", value: false },
      ],
      brand: [{ name: "GPT-3", value: false }],
      category: [{ name: "3D", value: false }],

      ref: this.$route.params.ref,
      ADMIN: ADMIN,
    };
  },
  components: {
    ConnectWallet,
  },
  computed: mapGetters(["getUser"]),

  methods: {
    changeMenu() {
      if (this.showMenu != "") {
        this.showMenu = "";
      } else {
        this.showMenu = "hidden lg:block";
      }
    },
    async getNewPrompts() {
      var p = this.mode.filter((m) => {
        return m.value == true;
      });

      await Prompt.AdminListPrompt({
        status: p[0].name,
      })
        .then((res) => {
          this.prompts = res.data.prompts;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    changeMode(text) {
      this.mode = this.mode.map((m) => {
        if (text == m.name) {
          m.value = true;

          return m;
        } else {
          m.value = false;

          return m;
        }
      });

      this.getNewPrompts();
    },
    checkAdmin() {
      if (this.getUser) {
        var bbb = this.ADMIN.filter((a) => {
          return a.toLowerCase() == this.getUser.address.toLowerCase();
        });

        if (bbb.length == 0) {
          this.$router.push("/");
        } else {
          this.getNewPrompts();
        }
      }
    },
  },
  created() {
    this.checkAdmin();
  },
};
</script>
